import * as actions from '../../../../actions/ReActions/actionTypes'

const initialState = {
    corp_name: '',
    corp_city: '',
    corp_prefix: '',
    corp_manager: [],
    corp_room: '',
    corp_own: '',
    ownArr: [],
    managerArr: [],
    corp_manager_search: ''
}


export default function CorporationReducer(state = initialState, action) {


    switch (action.type) {
        case actions.ADD_CORP_INFO:
            return { ...state, [action.payload.field_key]: action.payload.field_value }
        case actions.ADD_CORP_MANAGER:
            console.log("test call corp manager")
            // return { ...state, [action.payload.field_key]: action.payload.field_value }
            return {
                ...state, corp_manager:
                    [...state.corp_manager, action.payload ]
            }
        case actions.ADD_USER_LIST_CORP:
            return {
                ...state, [action.payload.type]: action.payload['user_array']
            }
        case actions.DELETE_MANAGER_USER:
            let index_element = state.corp_manager.findIndex(index => index == action.payload);
            state.corp_manager.splice(index_element, 1);
            return { ...state, corp_manager: state.corp_manager }
        case actions.CLEAR_VALUE_CORP:
            return {
                corp_name: '',
                corp_city: '',
                corp_prefix: '',
                corp_manager: [],
                corp_room: '',
                corp_own: '',
                ownArr: [],
                managerArr: [],
                corp_manager_search: ''
            }
        default:
            return state
    }
}
