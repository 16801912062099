import * as actions from '../../../../actions/ReActions/actionTypes'


const initialState = {
    role: null
}

export default function PermissionReducer(state = initialState, action) {
    switch (action.type) {
        case actions.ADD_ROLE_INFO:
            return state.role = action.payload
        default:
            return state
    }
}
