import React from 'react';
import { _t } from "../../../../../../../languageHandler";

import { Icon as CancelRounded } from "../../../../../../../../res/img/element-icons/cancel-rounded.svg";

// return-button.svg

import { Icon as ReturnRounded } from "../../../../../../../../res/img/re-icon/return-button.svg";

import PropTypes from "prop-types";



class MessageControlElement extends React.Component {

    static propTypes = {
        closeSettingsFn: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            filter_name: this.props.filter_type,
            filter_value: this.props.filter_value,
            filter_action: this.props.filter_action,
            filter_is_active: this.props.is_acive,
            back_up_data: {},
            isBackView: false,
        };
    }

    oncheckChanges = (value='', type='') => {
        console.log("check changes in object");
        let current_obj = {
            "value": this.state.filter_value,
            "is_active": this.state.filter_is_active,
            "action": this.state.filter_action,
        }

        if (type == "value") {
            current_obj.value = parseInt(value);
        } else if (type == 'action') {
            current_obj.action = value
        } else if (type == 'active'){
            current_obj.is_active = value
        }

        if (JSON.stringify(current_obj) == JSON.stringify(this.state.back_up_data)) {
            console.log("this same data");
            this.setState({isBackView: false})
        } else {
            console.log("this diff data");
            this.setState({isBackView: true})
        }
    }

    onCreateBackUpData = () => {
        let back_obj = {
            "value": this.state.filter_value,
            "is_active": this.state.filter_is_active,
            "action": this.state.filter_action,
        };

        if (this.props.isBack) {
            this.props.isBack_function()
        }

        if(this.state.isBackView == true){
            this.setState({isBackView: false})
        }

        this.setState({
            back_up_data: back_obj,
        })
    }

    componentDidMount = () => {
        let back_obj = {
            "value": this.props.filter_value,
            "is_active": this.props.is_acive,
            "action": this.props.filter_action,
        };

        this.setState({
            back_up_data: back_obj,
        })

        // this.setState({
        //     filter_name: this.props.filter_type,
        //     filter_value: this.props.filter_value,
        //     filter_action: this.props.filter_action,
        //     filter_is_active: this.props.is_acive,
        //     back_up_data: back_obj,
        // })
    }

    onHandleChangeValueSettings = (event) => {
        let value_event = parseInt(event.target.value);

        if(value_event > 100){
            value_event = 99
        }

        this.oncheckChanges(value_event, "value");
        this.setState({ filter_value: parseInt(value_event) });
        this.props.edit_function(this.state.filter_name, value_event, "value");
    }

    onHandleChangeActionSettings = (event) => {
        this.oncheckChanges(event.target.value, "value");
        this.setState({ filter_action: event.target.value });
        this.props.edit_function(this.state.filter_name, event.target.value, "action");
    }

    onDeleteFilter = () => {
        let active_status = !this.state.filter_is_active;
        this.oncheckChanges(active_status, "active");
        this.setState({
            filter_value: 0,
            filter_action: "ignore",
            filter_is_active: active_status,
        });
        this.props.edit_function(this.state.filter_name, active_status, "active");
    }


    onReturnDefaultData = () => {
        this.setState({
            filter_value: this.state.back_up_data.value,
            filter_action: this.state.back_up_data.action,
            filter_is_active: this.state.back_up_data.is_active,
            isBackView: false,
        });
        this.props.edit_function(this.state.filter_name, this.state.back_up_data, "return_data");
    }


    render() {

        let cancelButtonClass = '', contentView;

        if ((this.props.isEdit) && (this.props.isBack)) {
            // console.log("create backup data");
            this.onCreateBackUpData();
        }

        if (this.state.isBackView == false){
            // console.log("hidden edit button")
            cancelButtonClass = "re_hidden_button"
        } else{
            // console.log("view cancel button")
            cancelButtonClass = ""
        }

        if(this.props.isEdit == false){
            contentView = (
                <div className='mx_Tag re_main__filter_container re_main_no_edit_filter_container'>
                    <div className='re_filter_box_container'>
                        <span className='re_filter_box_header'>{this.props.filter_label}</span>
                        <hr className='re_delimiter_box' />
                        <div className='re_value_info_box'>
                            <span>{_t("service_message_power_level")}:</span>
                            <span>{this.state.filter_value}</span>
                        </div>
                        <div className='re_value_info_box'>
                            <span>{_t("service_message_restriction_type")}:</span>
                            <span>{this.state.filter_action}</span>
                        </div>
                    </div>
                </div>
            )
            
        } else{
            contentView = (
                <div className={'mx_Tag re_main__filter_container ' + (this.state.filter_is_active == false ? 're_delete_filter' : '')}>
                    <div className='re_filter_box_container'>
                        <span className='re_filter_box_header'>{this.props.filter_label}</span>
                        <div className='mx_Field mx_Field_input re_filter_box_input'>
                            <input id="power_level_filter" type='number' value={this.state.filter_value}
                                onChange={this.onHandleChangeValueSettings}
                                disabled={!this.props.isEdit} />
                            <label for="power_level_filter">{_t("service_message_power_level")}</label>
                        </div>
                        <div className='mx_Field mx_Field_select re_filter_box_input'>
                            <select id="type_action" value={this.state.filter_action}
                                onChange={this.onHandleChangeActionSettings}
                                disabled={!this.props.isEdit}>
                                <option key="ignore" value="ignore">ignore</option>
                                <option key="last" value="last">last</option>
                            </select>
                            <label for="type_action">{_t("service_message_restriction_type")}</label>
                        </div>
                    </div>

                    <div aria-label='Cancel' role='button'
                        className={'mx_AccessibleButton mx_Tag_delete ' + cancelButtonClass}
                        onClick={this.onReturnDefaultData}>
                        <ReturnRounded />
                    </div>

                    <div aria-label='Remove' role='button'
                        className={'mx_AccessibleButton mx_Tag_delete ' + (this.props.isEdit == false ? 're_hidden_button' : '')}
                        onClick={this.onDeleteFilter}>
                        <CancelRounded />
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                {contentView}
                {/* + (this.state.filter_is_active == false ? 're_delete_filter' : '') */}
                {/* <div className={'mx_Tag re_main__filter_container ' + (this.state.filter_is_active == false ? 're_delete_filter' : '')}>
                    <div className='re_filter_box_container'>
                        <span className='re_filter_box_header'>{this.props.filter_label}</span>
                        <div className='mx_Field mx_Field_input re_filter_box_input'>
                            <input id="power_level_filter" type='number' value={this.state.filter_value}
                                onChange={this.onHandleChangeValueSettings}
                                disabled={!this.props.isEdit} />
                            <label for="power_level_filter">{_t("service_message_power_level")}</label>
                        </div>
                        <div className='mx_Field mx_Field_select re_filter_box_input'>
                            <select id="type_action" value={this.state.filter_action}
                                onChange={this.onHandleChangeActionSettings}
                                disabled={!this.props.isEdit}>
                                <option key="ignore" value="ignore">ignore</option>
                                <option key="last" value="last">last</option>
                            </select>
                            <label for="type_action">{_t("service_message_restriction_type")}</label>
                        </div>
                    </div>

                    <div aria-label='Cancel' role='button'
                        className={'mx_AccessibleButton mx_Tag_delete ' + cancelButtonClass}
                        onClick={this.onReturnDefaultData}>
                        <ReturnRounded />
                    </div>

                    <div aria-label='Remove' role='button'
                        className={'mx_AccessibleButton mx_Tag_delete ' + (this.props.isEdit == false ? 're_hidden_button' : '')}
                        onClick={this.onDeleteFilter}>
                        <CancelRounded />
                    </div>
                </div> */}
            </React.Fragment>
        )
    }

}

export default MessageControlElement
