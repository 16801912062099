import * as actions from '../../../../actions/ReActions/actionTypes'

const initialState = {
    kind_activity: "",
    location_activity: "",
    start_date_activity: "",
    end_date_activity: "",
    point_activity: 0,
    during_day: 0,
    activity_array: [],
    first_name: '',
    last_name: '',
    middle_name: '',
    work_place: '',
    position_work: '',
    phone: '',
    email: '',
    isValidemail: false,
    isValidPhone: false,
    isActualPorfolio: false
}


export default function PortfolioReducer(state = initialState, action) {


    switch (action.type) {
        case actions.ADD_VALUE_ACTIVITY:
            // return { ...state, kind_activity: action.payload}
            return { ...state, [action.payload.field_key]: action.payload.field_value }
        case actions.ADD_ARRAY_ACTIVITY_TABLE:
            return { ...state, activity_array: [...state.activity_array, action.payload] }
        case actions.CLEAR_VALUE_ACTIVITY:
            return {
                ...state, kind_activity: '', location_activity: '', start_date_activity: '',
                end_date_activity: '', point_activity: 0, during_day: 0
            }
        case actions.ADD_GENERAL_PORTFOLIO_INFO:
            return { ...state, [action.payload.field_key]: action.payload.field_value }
        case actions.CLEAR_VALUE_PORTFOLIO:
            return {
                kind_activity: "",
                location_activity: "",
                start_date_activity: "",
                end_date_activity: "",
                point_activity: 0,
                during_day: 0,
                activity_array: [],
                first_name: '',
                last_name: '',
                middle_name: '',
                work_place: '',
                position_work: '',
                phone: '',
                email: '',
                isValidemail: false,
                isValidPhone: false,
                isActualPorfolio: false
            }
        default:
            return state
    }
}
