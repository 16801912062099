import * as actions from '../../../../actions/ReActions/actionTypes'


const BusicInfoNeedReducer = (state = {}, action) => {
    switch (action.type) {
        case actions.CHANGE_NEED_CATEGORY: {
            state = { ...state, "re.need.category": action.payload }
            break;
        }
        case actions.CHANGE_NEED_CATEGORY_DETAILING: {
            state = { ...state, "re.need.categoryDetailing": action.payload }
            break;
        }
        case actions.CHANGE_NEED_DESCRIPTION: {
            state = { ...state, "re.need.description": action.payload }
            break;
        }
        case actions.CHANGE_NEED_HEADING: {
            state = { ...state, "re.need.heading": action.payload }
            break;
        }
        case actions.CHANGE_NEED_BUSIC_OBJECT: {
            state = action.payload
            break;
        }
        case actions.CLEAR_NEED_GENERAL: {
            state = {}
            break;
        }
    }
    return state
}

export default BusicInfoNeedReducer
