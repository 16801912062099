"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseMMessage = parseMMessage;

var _MessageEvent = require("../../events/MessageEvent");

var _message_types = require("../../events/message_types");

var _EmoteEvent = require("../../events/EmoteEvent");

var _NoticeEvent = require("../../events/NoticeEvent");

/*
Copyright 2022 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
function parseMMessage(wireEvent) {
  if (_message_types.M_EMOTE.matches(wireEvent.type)) {
    return new _EmoteEvent.EmoteEvent(wireEvent);
  } else if (_message_types.M_NOTICE.matches(wireEvent.type)) {
    return new _NoticeEvent.NoticeEvent(wireEvent);
  } // default: return a generic message


  return new _MessageEvent.MessageEvent(wireEvent);
}