// Permission Action Type
export const ADD_ROLE_INFO = 'ADD_ROLE_INFO';

// Interface Action Type
export const CHANGE_FORM_NAME = 'CHANGE_FORM_NAME';
export const ADD_NEW_BREADCRUMB = 'ADD_NEW_BREADCRUMB';
export const DELETE_BREADCRUMB = 'DELETE_BREADCRUMB';
export const LAST_DELETE_BREADCRUMB = 'LAST_DELETE_BREADCRUMB';

// Portfolio Action Type
export const ADD_VALUE_ACTIVITY = 'ADD_VALUE_ACTIVITY';
export const ADD_ARRAY_ACTIVITY_TABLE = 'ADD_ARRAY_ACTIVITY_TABLE';
export const CLEAR_VALUE_ACTIVITY = 'CLEAR_VALUE_ACTIVITY';
export const ADD_GENERAL_PORTFOLIO_INFO = 'ADD_GENERAL_PORTFOLIO_INFO';
export const CLEAR_VALUE_PORTFOLIO = 'CLEAR_VALUE_PORTFOLIO';

// Corporation Type
export const ADD_CORP_INFO = 'ADD_CORP_INFO';
export const CLEAR_VALUE_CORP = 'CLEAR_VALUE_CORP';
export const ADD_CORP_MANAGER = 'ADD_CORP_MANAGER';
export const DELETE_MANAGER_USER = 'DELETE_MANAGER_USER';
export const ADD_USER_LIST_CORP = 'ADD_USER_LIST_CORP';
export const GET_USER_LIST_CORP = 'GET_USER_LIST_CORP';
export const ADD_CORP_FOR_EDIT = 'ADD_CORP_FOR_EDIT';
export const CLEAR_VALUE_CORP_EDIT = 'CLEAR_VALUE_CORP_EDIT';

//Needs and proposals Action Type
// Needs Basic Info Action
export const CHANGE_NEED_CATEGORY = 'CHANGE_NEED_CATEGORY';
export const CHANGE_NEED_CATEGORY_DETAILING = 'CHANGE_NEED_CATEGORY_DETAILING';
export const CHANGE_NEED_DESCRIPTION = 'CHANGE_NEED_DESCRIPTION';
export const CHANGE_NEED_HEADING = 'CHANGE_NEED_HEADING';
export const CLEAR_NEED_GENERAL = 'CLEAR_NEED_GENERAL';
export const CHANGE_NEED_BUSIC_OBJECT = 'CHANGE_NEED_BUSIC_OBJECT';

// Needs Edit Action
export const CHANGE_NEED_STATE_IS_EDIT = 'CHANGE_NEED_STATE_IS_EDIT';
export const CHANGE_NEED_STATE_OBJECT = 'CHANGE_NEED_STATE_OBJECT';
export const CHANGE_NEED_ROOM_ID = 'CHANGE_NEED_ROOM_ID';
export const CHANGE_NEED_STATE_DEFAULT = 'CHANGE_NEED_STATE_DEFAULT';

// Needs Imag Action
export const CHANGE_NEED_FILE = 'CHANGE_NEED_FILE';
export const CHANGE_NEED_FILE_SEND = 'CHANGE_NEED_FILE_SEND';
export const CHANGE_NEED_FILE_VALUE = 'CHANGE_NEED_FILE_VALUE';
export const CHANGE_NEED_VISIBLE_CONTAINER = 'CHANGE_NEED_VISIBLE_CONTAINER';
export const CHANGE_NEED_FILE_NAME = 'CHANGE_NEED_FILE_NAME';
export const SAVE_MXC_URL = 'SAVE_MXC_URL';
export const CHANGE_NEED_DELETE_IMAGE = 'CHANGE_NEED_DELETE_IMAGE';

// Needs Period Action
export const CHANGE_NEED_MIN_START_DATE = 'CHANGE_NEED_MIN_START_DATE';
export const CHANGE_NEED_MIN_START_TIME = 'CHANGE_NEED_MIN_START_TIME';
export const CHANGE_NEED_START_DATE = 'CHANGE_NEED_START_DATE';
export const CHANGE_NEED_START_TIME = 'CHANGE_NEED_START_TIME';
export const CHANGE_NEED_MAX_DATE = 'CHANGE_NEED_MAX_DATE';
export const CHANGE_NEED_END_TIME = 'CHANGE_NEED_END_TIME';
export const CHANGE_NEED_END_DATE = 'CHANGE_NEED_END_DATE';
export const CHANGE_NEED_SYNC_DATE = 'CHANGE_NEED_SYNC_DATE';
export const CHANGE_NEED_PLUS_DAY_DATE = 'CHANGE_NEED_PLUS_DAY_DATE';
export const CHANGE_NEED_IS_DURING = 'CHANGE_NEED_IS_DURING';
export const CLEAR_NEED_DURING = 'CLEAR_NEED_DURING';
export const CHANGE_NEED_PERIOD_OBJECT = 'CHANGE_NEED_PERIOD_OBJECT';

// Needs Searchable Action
export const CHANGE_NEED_IS_SEARCHABLE = 'CHANGE_NEED_IS_SEARCHABLE';
export const CHANGE_NEED_IS_AUDIENCE = 'CHANGE_NEED_IS_AUDIENCE';
export const CHANGE_NEED_AUDIENCE = 'CHANGE_NEED_AUDIENCE';
export const CHANGE_NEED_IS_ID = 'CHANGE_NEED_IS_ID';
export const CHANGE_NEED_ID = 'CHANGE_NEED_ID';
export const CHANGE_NEED_MIN_IS_PHONE = 'CHANGE_NEED_MIN_IS_PHONE';
export const CHANGE_NEED_PHONE = 'CHANGE_NEED_PHONE';
export const CHANGE_NEED_IS_EMAIL = 'CHANGE_NEED_IS_EMAIL';
export const CHANGE_NEED_EMAIL = 'CHANGE_NEED_EMAIL';
export const CHANGE_NEED_IS_GEO = 'CHANGE_NEED_IS_GEO';
export const CHANGE_NEED_GEO = 'CHANGE_NEED_GEO';
export const CHANGE_NEED_IS_TAG = 'CHANGE_NEED_IS_TAG';
export const CHANGE_NEED_TAG = 'CHANGE_NEED_TAG';
export const CHANGE_NEED_VALID_EMAIL = 'CHANGE_NEED_VALID_EMAIL';
export const CHANGE_NEED_VALID_PHONE = 'CHANGE_NEED_VALID_PHONE';
export const CHANGE_NEED_LAT_GEO = 'CHANGE_NEED_LAT_GEO';
export const CHANGE_NEED_LON_GEO = 'CHANGE_NEED_LON_GEO';
export const CHANGE_NEED_RADIUS = 'CHANGE_NEED_RADIUS';
export const CHANGE_NEED_VALID_LAT = 'CHANGE_NEED_VALID_LAT';
export const CHANGE_NEED_VALID_LON = 'CHANGE_NEED_VALID_LON';
export const CHANGE_NEED_VALID_RADIUS = 'CHANGE_NEED_VALID_RADIUS';
export const CHANGE_NEED_AUDIENCE_FOCUS = 'CHANGE_NEED_AUDIENCE_FOCUS';
export const CHANGE_NEED_AUDIENCE_PRIORITY = 'CHANGE_NEED_AUDIENCE_PRIORITY';
export const CHANGE_NEED_AUDIENCE_VISIBLE_ALL = 'CHANGE_NEED_AUDIENCE_VISIBLE_ALL';
export const CHANGE_NEED_ID_FOCUS = 'CHANGE_NEED_ID_FOCUS';
export const CHANGE_NEED_ID_PRIORITY = 'CHANGE_NEED_ID_PRIORITY';
export const CHANGE_NEED_ID_VISIBLE_ALL = 'CHANGE_NEED_ID_VISIBLE_ALL';
export const CHANGE_NEED_PHONE_FOCUS = 'CHANGE_NEED_PHONE_FOCUS';
export const CHANGE_NEED_PHONE_PRIORITY = 'CHANGE_NEED_PHONE_PRIORITY';
export const CHANGE_NEED_PHONE_VISIBLE_ALL = 'CHANGE_NEED_PHONE_VISIBLE_ALL';
export const CHANGE_NEED_EMAIL_FOCUS = 'CHANGE_NEED_EMAIL_FOCUS';
export const CHANGE_NEED_EMAIL_PRIORITY = 'CHANGE_NEED_EMAIL_PRIORITY';
export const CHANGE_NEED_EMAIL_VISIBLE_ALL = 'CHANGE_NEED_EMAIL_VISIBLE_ALL';
export const CHANGE_NEED_GEO_FOCUS = 'CHANGE_NEED_GEO_FOCUS';
export const CHANGE_NEED_GEO_PRIORITY = 'CHANGE_NEED_GEO_PRIORITY';
export const CHANGE_NEED_GEO_VISIBLE_ALL = 'CHANGE_NEED_GEO_VISIBLE_ALL';
export const CHANGE_NEED_TAG_FOCUS = 'CHANGE_NEED_TAG_FOCUS';
export const CHANGE_NEED_TAG_PRIORITY = 'CHANGE_NEED_TAG_PRIORITY';
export const CHANGE_NEED_TAG_VISIBLE_ALL = 'CHANGE_NEED_TAG_VISIBLE_ALL';
export const CHANGE_NEED_VISIBLE_CONTAINER_SEARCH = 'CHANGE_NEED_VISIBLE_CONTAINER_SEARCH';
export const CLEAR_NEED_SEARCHABLE = 'CLEAR_NEED_SEARCHABLE';
export const CHANGE_NEED_SEARCHABLE_OBJECT = 'CHANGE_NEED_SEARCHABLE_OBJECT';

// Needs Final Action
export const CHANGE_NEED_VISIBLE = 'CHANGE_NEED_VISIBLE';
export const CHANGE_NEED_DELETE_FINAL = 'CHANGE_NEED_DELETE_FINAL';

