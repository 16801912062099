import { combineReducers } from 'redux'
import TestReducer from './SubStores/TestReducer'
// Permission Store
import PermissionReducer from './SubStores/PermissionStores/PermissionReducer'
// Needs And Propsasls Store
import BusicInfoNeedReducer from './SubStores/NeedsAndProposalsStores/BusicInfoNeedReducer'
import PeriodNeedReducer from './SubStores/NeedsAndProposalsStores/PeriodNeedReducer'
import UploadImageNeedReducer from './SubStores/NeedsAndProposalsStores/UploadImageNeedReducer'
import SearchableNeedReducer from './SubStores/NeedsAndProposalsStores/SearchableNeedReducer'
import ReFinalNeedReducer from './SubStores/NeedsAndProposalsStores/ReFinalNeedReducer'
import ReNeedEditReducer from "./SubStores/NeedsAndProposalsStores/ReNeedEditReducer"

// Portfolio Reducer
import InterfaceReducer from "./SubStores/InterfaceStores/InterfaceReducer"
import PortfolioReducer from "./SubStores/InterfaceStores/PortfolioReducer"

// Corporation Reducer
import CorporationReducer from "./SubStores/InterfaceStores/CorporationReducer"
import CorpListReducer from "./SubStores/InterfaceStores/CorpListReducer"
import CorporationEditReducer from './SubStores/InterfaceStores/CorporationEditReducer'


const rootReducer = combineReducers({
    test: TestReducer,
    permission: PermissionReducer,
    interfaces: InterfaceReducer,
    portfolio_create: PortfolioReducer,
    corp_create: CorporationReducer,
    corp_list: CorpListReducer,
    corp_edit: CorporationEditReducer,
    BusicInfoNeedReducer,
    PeriodNeedReducer,
    UploadImageNeedReducer,
    SearchableNeedReducer,
    ReFinalNeedReducer,
    ReNeedEditReducer,
})

export default rootReducer
