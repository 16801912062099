import React from 'react';
import { _t } from "../../../../../../languageHandler";

import PropTypes from "prop-types";



class MessageControlAddComponent extends React.Component {

    static propTypes = {
        closeSettingsFn: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            curr_filter: "",
            curr_value: 0,
            curr_action: "ignore"
        };
    }


    handleChangeTypeFilter = (event) => {
        this.setState({ curr_filter: event.target.value })
    }


    handleChangeValueFilter = (event) => {
        let value_event = event.target.value;

        if(value_event > 100){
            value_event = 99
        }
        this.setState({ curr_value: value_event })
    }

    handleChangeActionFilter = (event) => {
        this.setState({ curr_action: event.target.value })
    }


    createOptionMessageType = (filters_list) => {
        let keys_filter = Object.keys(filters_list);
        return keys_filter.map((key, index) => {
            // let curr_element = filters_list[key];
            if (filters_list[key].is_active == false) {
                const translate_value = this.props.translate_obj;
                let label = _t(translate_value[key]);
                return <option key={key + index.toString()} value={key}>{label}</option>
            }
        })
    }

    activeAllFilters = (filters_setting) => {
        let keys_filter = Object.keys(filters_setting);
        keys_filter.map((key, index) => {
            if (key != 're.all.filter') {
                filters_setting[key].is_active = false
                filters_setting[key].value = 0
                filters_setting[key].action = "ignore"
            } else {
                filters_setting[key].is_active = true
                filters_setting[key].value = parseInt(this.state.curr_value)
                filters_setting[key].action = this.state.curr_action
            }

        })
        return filters_setting
    }

    addFilter = () => {
        let filter_obj = this.props.settings_value;
        if (this.state.curr_filter == 're.all.filter') {
            filter_obj = this.activeAllFilters(filter_obj)
        } else {
            if(filter_obj["re.all.filter"].is_active == true){
                let change_value_all = filter_obj["re.all.filter"]
                change_value_all['is_active'] = false;
                change_value_all['value'] = 0;
                change_value_all['action'] = "ignore";
            }
            let change_value = filter_obj[this.state.curr_filter]
            change_value['is_active'] = true;
            change_value['value'] = parseInt(this.state.curr_value);
            change_value['action'] = this.state.curr_action;
        }
        // let change_value = filter_obj[this.state.curr_filter]
        // change_value['is_active'] = true;
        // change_value['value'] = parseInt(this.state.curr_value);
        // change_value['action'] = this.state.curr_action;
        this.setState({
            curr_filter: "",
            curr_value: 0,
            curr_action: "ignore"
        });
        this.props.update_function(filter_obj);
    }


    render() {

        return (
            <React.Fragment>
                <div className='service_message_add_container'>
                    <div className='mx_Field mx_Field_select'>
                        <select id="filter_type" value={this.state.curr_filter}
                            onChange={this.handleChangeTypeFilter}>
                            <option value="" selected disabled hidden>{_t("Choose filter")}</option>
                            {this.createOptionMessageType(this.props.settings_value)}
                        </select>
                        <label for="filter_type">{_t("service_message_filter")}</label>
                    </div>
                    <div className='service_message_add_power_action_container'>
                        <div className='mx_Field mx_Field_input'>
                            <input id="power_level_filter" type='number' value={this.state.curr_value}
                                onChange={this.handleChangeValueFilter} />
                            <label for="power_level_filter">{_t("service_message_power_level")}</label>
                        </div>
                        <div className='mx_Field mx_Field_select'>
                            <select id="type_action" value={this.state.curr_action} onChange={this.handleChangeActionFilter}>
                                <option key="ignore" value="ignore">ignore</option>
                                <option key="last" value="last">last</option>
                            </select>
                            <label for="type_action">{_t("service_message_restriction_type")}</label>
                        </div>
                    </div>
                </div>
                {/* className='re_dialog_button'  */}
                <button type="button" id='re_dialog_button' onClick={this.addFilter}>
                    {_t("re_text_add")}
                </button>


            </React.Fragment>
        )
    }

}

export default MessageControlAddComponent
