import React from 'react';
import { _t } from "../../../../../../languageHandler";

import MessageControlElement from "./elements/MessageControlElement"

import PropTypes from "prop-types";

import configData from "../../../../../../../envConfig.json";



class MessageControlUpdateComponent extends React.Component {

    static propTypes = {
        closeSettingsFn: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            editing_settings: this.props.settings_value,
            isBackSave: false,
        };
    }

    // createSettingsObject = (data) => {
    //     let settings_obj = {}
    //     let keys_filter = Object.keys(data);
    //     keys_filter.map((key, index) => {
    //         let current_element = data[key];
    //         return settings_obj[key] = current_element
    //     })

    //     return settings_obj
    // }

    // componentDidMount = () => {

    //     // let cloneData = this.createSettingsObject(this.props.settings_value);

    //     this.setState({
    //         editing_settings: this.props.settings_value,
    //     })
    // }

    onAddFilterElement = (filters_list) => {
        let keys_filter = Object.keys(filters_list);

        return keys_filter.map((key, index) => {
            if (filters_list[key].is_active == true) {
                const translate_value = this.props.translate_obj;
                let current_element = filters_list[key];
                let label = _t(translate_value[key]);
                return (<MessageControlElement filter_label={label} filter_value={current_element.value}
                    filter_action={current_element.action}
                    filter_type={key}
                    is_acive={current_element.is_active}
                    isEdit={this.state.isEdit}
                    isBack_function = {this.onBackSave}
                    isBack = {this.state.isBackSave}
                    edit_function={this.onChangeEditSettings} />)
            }
        })
    }


    onBackSave = () =>{
        if(this.state.isEdit == false){
            this.setState({isBackSave: true})
        } else{
            this.setState({isBackSave: false})
        }
        
    }


    onClickEditButton = () => {
        this.setState({ isEdit: !this.state.isEdit });

        if(this.state.isEdit == false){
            this.onBackSave();
        }

        if (this.state.isEdit) {
            console.log("Save changes in filter");
            // this.onCheckViewComponent();
            this.props.update_function(this.state.editing_settings);
            this.forceUpdate();
        }
    }

    onClickCancelButton = () => {

        this.setState({
            isEdit: !this.state.isEdit,
        });
        // this.onAddFilterElement(this.state.cancel_setting);
        this.forceUpdate();
    }

    onChangeEditSettings = (settings_type, value, value_type) => {
        // console.log("Settings value for edit");
        // console.log(settings_type);
        // console.log(value);

        let settings_obj = this.state.editing_settings;
        let current_filter = settings_obj[settings_type];
        if (value_type == "value") {
            current_filter.value = parseInt(value);
        } else if (value_type == 'action') {
            current_filter.action = value;
        } else if (value_type == 'active') {
            current_filter.is_active = value;
            current_filter.value = 0;
            current_filter.action = "ignore"
        } else if (value_type == 'return_data'){
            current_filter.is_active = value.is_active;
            current_filter.value = value.value;
            current_filter.action = value.action;
        }

        this.state.editing_settings = settings_obj;
        // this.state.data_change = isChangeData
        // this.setState({
        //     editing_settings: settings_obj,
        //     data_change: isChangeData
        // });
    }


    render() {

        console.log("check change props");
        console.log(this.props.settings_value);

        let cancle_button;

        if (this.state.isEdit) {
            cancle_button = (<button type="button" id='re_dialog_button_cancel' onClick={this.onClickCancelButton}>
                {_t("re_text_cancel")}
            </button>)
        }

        return (
            <React.Fragment>
                <div className='re_filter_added_container'>
                    <div className='re_filter_edit_container'>
                        {/* className='re_dialog_button' */}
                        <button type="button" id='re_dialog_button' onClick={this.onClickEditButton}>
                            {this.state.isEdit == false ? _t("re_text_edit") : _t("re_text_save")}
                        </button>
                        {cancle_button}
                    </div>
                    {/* editing_settings */}
                    {/* {this.onAddFilterElement(this.state.editing_settings)} */}
                    {this.onAddFilterElement(this.props.settings_value)}
                </div>
            </React.Fragment>
        )
    }

}

export default MessageControlUpdateComponent
