
const TestReducer = () => {
    return [
        {
            id: 1,
            model: "Audi",
            speed: 234
        },
        {
            id: 2,
            model: "BMW",
            speed: 320
        }
    ]
}

export default TestReducer
