import * as actions from '../../../../actions/ReActions/actionTypes'


/*
isAudience: false,
            isIndetificator: false,
            isPhone: false,
            isEmail: false,
            isGeo: false,
            isTag: false,
            AudienceNeed: "",
            isFocusAdience: false,
            AudiencePriority: 0,
            AudienceVisbleAll: false,
            IndetifictaorNeed: "",
            PhoneNeed: "",
            EmailNeed: "",
            GeoNeed: null,
            TagNeed: "",
            AvailbeInfo: null,
            isValidEmail: false,
            isValidPhone: false,
            isSearchable: false,
            latGeo: "",
            lonGeo: "",
            radiusNeed: "",
            isLatValid: false,
            isLonValid: false,
            isRadiusValid: false,
            isVisibleNeed: true,
            */

const SearchableNeedReducer = (state = {}, action) => {
    switch (action.type) {
        case actions.CHANGE_NEED_IS_SEARCHABLE: {
            state = { ...state, isSearchable: action.payload }
            break;
        }
        case actions.CHANGE_NEED_IS_AUDIENCE: {
            state = { ...state, isAudience: action.payload }
            break;
        }
        case actions.CHANGE_NEED_IS_ID: {
            state = { ...state, isIndetificator: action.payload }
            break;
        }
        case actions.CHANGE_NEED_MIN_IS_PHONE: {
            state = { ...state, isPhone: action.payload }
            break;
        }
        case actions.CHANGE_NEED_IS_EMAIL: {
            state = { ...state, isEmail: action.payload }
            break;
        }
        case actions.CHANGE_NEED_IS_GEO: {
            state = { ...state, isGeo: action.payload }
            break;
        }
        case actions.CHANGE_NEED_IS_TAG: {
            state = { ...state, isTag: action.payload }
            break;
        }
        case actions.CHANGE_NEED_AUDIENCE: {
            state = { ...state, AudienceNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_ID: {
            state = { ...state, IndetifictaorNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_PHONE: {
            state = { ...state, PhoneNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_EMAIL: {
            state = { ...state, EmailNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_GEO: {
            state = { ...state, GeoNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_TAG: {
            state = { ...state, TagNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_VALID_EMAIL: {
            state = { ...state, isValidEmail: action.payload }
            break;
        }
        case actions.CHANGE_NEED_VALID_PHONE: {
            state = { ...state, isValidPhone: action.payload }
            break;
        }
        case actions.CHANGE_NEED_LAT_GEO: {
            state = { ...state, latGeo: action.payload }
            break;
        }
        case actions.CHANGE_NEED_LON_GEO: {
            state = { ...state, lonGeo: action.payload }
            break;
        }
        case actions.CHANGE_NEED_RADIUS: {
            state = { ...state, radiusNeed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_VALID_RADIUS: {
            state = { ...state, isRadiusValid: action.payload }
            break;
        }
        case actions.CHANGE_NEED_VALID_LAT: {
            state = { ...state, isLatValid: action.payload }
            break;
        }
        case actions.CHANGE_NEED_VALID_LON: {
            state = { ...state, isLonValid: action.payload }
            break;
        }
        case actions.CHANGE_NEED_AUDIENCE_FOCUS: {
            state = { ...state, isFocusAudience: action.payload }
            break;
        }
        case actions.CHANGE_NEED_AUDIENCE_PRIORITY: {
            state = { ...state, AudiencePriority: action.payload }
            break;
        }
        case actions.CHANGE_NEED_AUDIENCE_VISIBLE_ALL: {
            state = { ...state, AudienceVisbleAll: action.payload }
            break;
        }
        case actions.CHANGE_NEED_ID_FOCUS: {
            state = { ...state, isFocusIndetifictaor: action.payload }
            break;
        }
        case actions.CHANGE_NEED_ID_PRIORITY: {
            state = { ...state, IndetifictaorPriority: action.payload }
            break;
        }
        case actions.CHANGE_NEED_ID_VISIBLE_ALL: {
            state = { ...state, IndetifictaorVisbleAll: action.payload }
            break;
        }
        case actions.CHANGE_NEED_PHONE_FOCUS: {
            state = { ...state, isFocusPhone: action.payload }
            break;
        }
        case actions.CHANGE_NEED_PHONE_PRIORITY: {
            state = { ...state, PhonePriority: action.payload }
            break;
        }
        case actions.CHANGE_NEED_PHONE_VISIBLE_ALL: {
            state = { ...state, PhoneVisbleAll: action.payload }
            break;
        }
        case actions.CHANGE_NEED_EMAIL_FOCUS: {
            state = { ...state, isFocusEmail: action.payload }
            break;
        }
        case actions.CHANGE_NEED_EMAIL_PRIORITY: {
            state = { ...state, EmailPriority: action.payload }
            break;
        }
        case actions.CHANGE_NEED_EMAIL_VISIBLE_ALL: {
            state = { ...state, EmailVisbleAll: action.payload }
            break;
        }
        case actions.CHANGE_NEED_GEO_FOCUS: {
            state = { ...state, isFocusGeo: action.payload }
            break;
        }
        case actions.CHANGE_NEED_GEO_PRIORITY: {
            state = { ...state, GeoPriority: action.payload }
            break;
        }
        case actions.CHANGE_NEED_GEO_VISIBLE_ALL: {
            state = { ...state, GeoVisbleAll: action.payload }
            break;
        }
        case actions.CHANGE_NEED_TAG_FOCUS: {
            state = { ...state, isFocusTag: action.payload }
            break;
        }
        case actions.CHANGE_NEED_TAG_PRIORITY: {
            state = { ...state, TagPriority: action.payload }
            break;
        }
        case actions.CHANGE_NEED_TAG_VISIBLE_ALL: {
            state = { ...state, TagVisbleAll: action.payload }
            break;
        }

        case actions.CHANGE_NEED_VISIBLE_CONTAINER_SEARCH :{
            state = {...state, isVisibleContainer :action.payload }
            break;
        }

        case actions.CHANGE_NEED_SEARCHABLE_OBJECT :{
            state = action.payload
            break;
        }

        case actions.CLEAR_NEED_SEARCHABLE: {
            state = {}
            break;
        }
    }
    return state
}

export default SearchableNeedReducer
