/*
Copyright 2022 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { HTMLAttributes } from "react";

export interface SettingsTabProps extends Omit<HTMLAttributes<HTMLDivElement>, "className"> {
    children?: React.ReactNode;
}

/**
 * Container for a tab of settings panel content
 * Should contain one or more SettingsSection
 * Settings width, padding and spacing between sections
 * Eg:
 * ```
 * <SettingsTab>
 *      <SettingsSection heading="General">
 *           <SettingsSubsection heading="Profile">
 *              // profile settings form
 *          <SettingsSubsection>
 *          <SettingsSubsection heading="Account">
 *              // account settings
 *          <SettingsSubsection>
 *      </SettingsSection>
 * </SettingsTab>
 * ```
 */
const SettingsTab: React.FC<SettingsTabProps> = ({ children, ...rest }) => (
    <div {...rest} className="mx_SettingsTab">
        <div className="mx_SettingsTab_sections">{children}</div>
    </div>
);

export default SettingsTab;
