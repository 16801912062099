import * as actions from '../../../../actions/ReActions/actionTypes'

const initialState = {
    corp_name: '',
    corp_city: '',
    corp_prefix: '',
    corp_manager: [],
    corp_room: '',
    corp_own: '',
    ownArr: [],
    managerArr: [],
    corp_manager_search: ''
}


export default function CorporationEditReducer(state = initialState, action) {


    switch (action.type) {
        case actions.ADD_CORP_FOR_EDIT:
            return state = action.payload
        case actions.CLEAR_VALUE_CORP_EDIT:
            return {
                corp_name: '',
                corp_city: '',
                corp_prefix: '',
                corp_manager: [],
                corp_room: '',
                corp_own: '',
                ownArr: [],
                managerArr: [],
                corp_manager_search: ''
            }
        default:
            return state
    }
}
