export const default_data = {
    "re.all.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.member.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    // "re.create.filter": {
    //     "value": 0,
    //     "is_active": false,
    //     "action": "ignore",
    // },
    "re.join_rules.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.history_visibility.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.guest_access.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.canonical_alias.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.name.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.power_levels.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.topic.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
    "re.avatar.filter": {
        "value": 0,
        "is_active": false,
        "action": "ignore",
    },
};


import React from 'react';
import { _t, _td } from "../../../../../../../languageHandler";

import PropTypes from "prop-types";

import MessageControlAddComponent from "../MessageControlAddComponent"
import MessageControlUpdateComponent from "../MessageControlUpdateComponent"

import configData from "../../../../../../../../envConfig.json";


class MessageControlTab extends React.Component {

    static propTypes = {
        closeSettingsFn: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            filters_data: {},
            added_filter: false,
        };
    }


    onCheckViewComponent = (data) => {
        let keys_filter = Object.keys(data);
        let cloneData = data;

        let add_filter = false;
        for (var i = 0; i < keys_filter.length; i++) {
            let key = keys_filter[i];
            if (cloneData[key].is_active == true) {
                add_filter = true;
                break;
            }

        }

        this.setState({
            added_filter: add_filter
        })
    }


    checkFilterStatef = async (token, url) => {
        let result = { "isAdd": false, "data": {} };
        try {
            let request = await fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            if (!request.ok) {
                console.log("request check filter not ok");
                return result
            }
            let responce = await request.json();
            if (responce.errcode == 'M_NOT_FOUND') {
                return result
            }
            result['isAdd'] = true;
            result['data'] = responce;
            return result
        } catch (error) {
            console.log("check filter error");
            console.log(error);
            result['data'] = error;
            return result

        }

    }


    sendStateFilterData = async (token, url, data = null) => {
        let filter_data;
        let result = { "isOk": false, "message": {} }
        if (data == null) {
            filter_data = default_data;
        } else {
            filter_data = data
        }

        try {
            let request = await fetch(url, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(filter_data),
            });
            if (!request.ok) {
                console.log("request check filter not ok");
                // result['message'] = request;
                return result
            }
            let responce = await request.json();
            result['isOk'] = true;
            result['message'] = responce
            return result

        } catch (error) {
            console.log("add filter error");
            console.log(error);
            result['message'] = error;
            return result

        }

    }


    addFilterMessage = async (data) => {
        console.log("addFilterMessage");
        let token = localStorage.getItem("re_access_token");
        let room_info = this.props.room;
        let roomId = String(room_info.roomId).replace(/!/i, '%21').replace(/:/i, '%3A')
        let sendRequest = `${configData.DEFAULT_SERVER_URL}_matrix/client/r0/rooms/${roomId}/state/re.service_message_filter`;
        let responce_update = await this.sendStateFilterData(token, sendRequest, data);
        if (responce_update.isOk) {
            console.log("data filter updata");
            this.onCheckViewComponent(data);
            this.setState({
                filters_data: data,
            });
        }
    }

    async componentDidMount() {
        let responce_check, responce_add;
        let token = localStorage.getItem("re_access_token");
        let room_info = this.props.room;
        let roomId = String(room_info.roomId).replace(/!/i, '%21').replace(/:/i, '%3A')
        let sendRequest = `${configData.DEFAULT_SERVER_URL}_matrix/client/r0/rooms/${roomId}/state/re.service_message_filter`;
        responce_check = await this.checkFilterStatef(token, sendRequest);
        if (responce_check.isAdd == false) {
            responce_add = await this.sendStateFilterData(token, sendRequest);
            if (responce_add.isOk) {
                console.log("data filter add");
                this.setState({
                    filters_data: default_data,
                });
            }
        } else {
            // start
            let filter_datas = responce_check.data;
            let get_keys_filter = Object.keys(responce_check.data);
            let standart_keys_filter = Object.keys(default_data);
            let isEqualKeys = _.isEqual(get_keys_filter, standart_keys_filter);
                        
            if (isEqualKeys != true) {
                let delete_element, add_element;
                delete_element = get_keys_filter.filter((element) => {
                    return standart_keys_filter.indexOf(element) == -1
                });

                add_element = standart_keys_filter.filter((element) => {
                    return get_keys_filter.indexOf(element) == -1
                });

                if (_.isEmpty(delete_element) == false) {
                    delete_element.map((element) =>{
                        filter_datas = _.omit(filter_datas, element)
                    })
                    console.log("new data after delete")
                    console.log(filter_datas)
                }

                if (_.isEmpty(add_element) == false) {
                    add_element.map((element) =>{
                        filter_datas[element] = {
                            "value": 0,
                            "is_active": false,
                            "action": "ignore",
                        }
                    })
                    console.log("new data after add")
                    console.log(filter_datas)
                }

                // if(get_keys_filter.length > standart_keys_filter.length){
                //     delete_element = get_keys_filter.filter((element) => {
                //         return standart_keys_filter.indexOf(element) == -1
                //     });
                //     if (_.isEmpty(delete_element) == false) {
                //         delete_element.map((element) =>{
                //             filter_datas = _.omit(filter_datas, element)
                //         })
                //         console.log("new data")
                //         console.log(filter_datas)
                //     }
                // } else if (get_keys_filter.length < standart_keys_filter.length){
                //     delete_element = standart_keys_filter.filter((element) => {
                //         return get_keys_filter.indexOf(element) == -1
                //     });
                //     if (_.isEmpty(delete_element) == false) {
                //         delete_element.map((element) =>{
                //             filter_datas[element] = {
                //                 "value": 0,
                //                 "is_active": false,
                //                 "action": "ignore",
                //             }
                //         })
                //         console.log("new data")
                //         console.log(filter_datas)
                //     }
                // }
                
                // if (_.isEmpty(delete_element) == true) {
                //     console.log("It is current filter")
                // } else {
                //     delete_element.map((element) =>{
                //         filter_datas = _.omit(filter_datas, element)
                //     })
                //     console.log("new data")
                //     console.log(filter_datas)
                // }
            }
            // end
            

            // filter_datas
            // responce_check.data
            this.onCheckViewComponent(filter_datas);
            this.setState({
                filters_data: filter_datas,
            })

        }
    }

    // cancelEditFunction = async () => {
    //     let responce_check, responce_add;
    //     let token = localStorage.getItem("re_access_token");
    //     let room_info = this.props.room;
    //     let roomId = String(room_info.roomId).replace(/!/i, '%21').replace(/:/i, '%3A')
    //     let sendRequest = `${configData.DEFAULT_SERVER_URL}_matrix/client/r0/rooms/${roomId}/state/re.service_message_filter`;
    //     responce_check = await this.checkFilterStatef(token, sendRequest);
    //     return responce_check
    // }


    render() {

        let add_filter_component = null, update_filter_component = null;

        const list_rations_name_type = {
            "re.all.filter": _td("Hide all messages"),
            "re.member.filter": _td("Hide invitation messages"),
            // "re.create.filter": _td("Hide room creation messages"),
            "re.join_rules.filter": _td("Hide messages about invitation permissions"),
            "re.history_visibility.filter": _td("Hide message display history messages"),
            "re.guest_access.filter": _td("Hide guest access messages"),
            "re.canonical_alias.filter": _td("Hide messages about setting an alias"),
            "re.name.filter": _td("Hide messages about room name changes"),
            "re.power_levels.filter": _td("Hide messages about power level changes"),
            "re.topic.filter": _td("Hide messages about room description changes"),
            "re.avatar.filter": _td("Hide messages about changing the room avatar"),
        }

        if (_.isEmpty(this.state.filters_data) == false) {
            add_filter_component = (<MessageControlAddComponent settings_value={this.state.filters_data}
                translate_obj={list_rations_name_type}
                update_function={this.addFilterMessage} />)
        }

        if ((this.state.added_filter == true) && (_.isEmpty(this.state.filters_data) == false)) {
            // check_change={this.onCheckChangeData}
            // cancel_function={this.cancelEditFunction}
            update_filter_component = (<MessageControlUpdateComponent settings_value={this.state.filters_data}
                translate_obj={list_rations_name_type}
                update_function={this.addFilterMessage} />)
        }


        return (
            <div className="mx_SettingsTab">
                {add_filter_component}
                {update_filter_component}
            </div>
        )
    }

}

export default MessageControlTab
