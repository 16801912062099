import * as actions from '../../../../actions/ReActions/actionTypes'


const ReNeedEditRedicer = (state={isEdit: false}, action) => {
    switch(action.type){
        case actions.CHANGE_NEED_STATE_IS_EDIT :{
            state = {...state, isEdit :action.payload }
            break;
        }

        case actions.CHANGE_NEED_STATE_OBJECT :{
            state = {...state, NeedInfo :action.payload }
            break;
        }

        case actions.CHANGE_NEED_ROOM_ID :{
            state = {...state, roomId :action.payload }
            break;
        }

        case actions.CHANGE_NEED_STATE_DEFAULT :{
            state = { isEdit: false }
            break;
        }
        
    }
        
    return state
  }
  
export default ReNeedEditRedicer
