import * as actions from '../../../../actions/ReActions/actionTypes'

const UploadImageNeedReducer = (state={}, action) => {
    switch(action.type){
        case actions.CHANGE_NEED_FILE :{
            state = {...state, file :action.payload }
            break;
        }
        case actions.CHANGE_NEED_FILE_SEND :{
            state = {...state, fileSend :action.payload }
            break;
        }
        case actions.CHANGE_NEED_FILE_VALUE :{
            state = {...state, fileValue :action.payload }
            break;
        }
        case actions.CHANGE_NEED_VISIBLE_CONTAINER :{
            state = {...state, isVisibleContainer :action.payload }
            break;
        }
        case actions.CHANGE_NEED_FILE_NAME :{
            state = {...state, fileName :action.payload }
            break;
        }
        case actions.SAVE_MXC_URL :{
            state = {...state, mxcUrl :action.payload }
            break;
        }
        case actions.CHANGE_NEED_DELETE_IMAGE :{
            state = {}
            break;
        }
    }
    return state
  }
  
export default UploadImageNeedReducer
