import * as actions from '../../../../actions/ReActions/actionTypes'

const initialState = {
    corp_list: []
}


export default function CorpListReducer(state = initialState, action) {


    switch (action.type) {
        case actions.GET_USER_LIST_CORP:
            return {...state, corp_list : action.payload}
        default:
            return state
    }
}
