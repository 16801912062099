import * as actions from '../../../../actions/ReActions/actionTypes'


// ,
//             {
//                 label: "Особисте освітнє портфоліо",
//                 form_name: "portfolio",
//                 isLink: false
//             }

const initialState = {
    portfolio: {
        current_page: "main",
        breadcrumbs: [
            {
                label: "Главная",
                form_name: "main",
                isLink: true
            }
        ]
    },
    corporation: {
        current_page: "main",
        breadcrumbs: [
            {
                label: "Главная",
                form_name: "main",
                isLink: true
            }
        ]
    }
}

function updateLinkStatus(element, status) {
    let arr_lengt, last_element;
    arr_lengt = element.breadcrumbs.length;
    last_element = element.breadcrumbs[arr_lengt - 1];
    last_element.isLink = status;
}

export default function InterfaceReducer(state = initialState, action) {


    switch (action.type) {
        case actions.CHANGE_FORM_NAME:
            // return { ...state, portfolio: { ...state['portfolio'], current_page: action.payload } }
            return {
                ...state, [action.payload.interface]:
                    { ...state[action.payload['interface']], current_page: action.payload['form_name'] }
            }
        case actions.ADD_NEW_BREADCRUMB:
            // update status link
            // updateLinkStatus(state.portfolio, true);
            // return { ...state, portfolio: { ...state.portfolio, 
            //                                 breadcrumbs: [...state.portfolio.breadcrumbs, action.payload] } }

            updateLinkStatus(state[action.payload.interface], true);
            return {
                ...state, [action.payload.interface]: {
                    ...state[action.payload.interface],
                    breadcrumbs: [...state[action.payload.interface].breadcrumbs, action.payload.breadcrumb]
                }
            }

        case actions.DELETE_BREADCRUMB:
            // let index_element = state.portfolio.breadcrumbs.findIndex(index => index.form_name == action.payload) + 1;
            // state.portfolio.breadcrumbs.splice(index_element);
            // // update status link
            // updateLinkStatus(state.portfolio, false);
            // return { ...state, portfolio: { ...state.portfolio, breadcrumbs: state.portfolio.breadcrumbs } }

            let index_element = state[action.payload.interface].breadcrumbs.findIndex(index => index.form_name == action.payload['form_name']) + 1;
            state[action.payload.interface].breadcrumbs.splice(index_element);
            // update status link
            updateLinkStatus(state[action.payload.interface], false);
            return {
                ...state, [action.payload.interface]: {
                    ...state[action.payload.interface],
                    breadcrumbs: state[action.payload.interface].breadcrumbs
                }
            }
        case actions.LAST_DELETE_BREADCRUMB:
            // state.portfolio.breadcrumbs.pop();
            // // update status link
            // updateLinkStatus(state.portfolio, false);
            // return { ...state, portfolio: { ...state.portfolio, breadcrumbs: state.portfolio.breadcrumbs } }

            state[action.payload].breadcrumbs.pop();
            // update status link
            updateLinkStatus(state[action.payload], false);
            return {
                ...state, [action.payload]: {
                    ...state[action.payload],
                    breadcrumbs: state[action.payload].breadcrumbs
                }
            }
        default:
            return state
    }
}
