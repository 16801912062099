import * as actions from '../../../../actions/ReActions/actionTypes'

/*
minStartTime: '',
            minStartDate: '',
            StartDate: '',
            startTime: '',
            maxDate: '',
            endTime: '',
            endDate: '',
            SyncDate: true,
            PlusDayDate: 21,
            isDuringneed: true
            */

const PeriodNeedReducer = (state = {}, action) => {
    switch (action.type) {
        case actions.CHANGE_NEED_MIN_START_DATE: {
            state = { ...state, minStartDate: action.payload }
            break;
        }
        case actions.CHANGE_NEED_MIN_START_TIME: {
            state = { ...state, minStartTime: action.payload }
            break;
        }
        case actions.CHANGE_NEED_START_DATE: {
            state = { ...state, StartDate: action.payload }
            break;
        }
        case actions.CHANGE_NEED_START_TIME: {
            state = { ...state, startTime: action.payload }
            break;
        }
        case actions.CHANGE_NEED_MAX_DATE: {
            state = { ...state, maxDate: action.payload }
            break;
        }
        case actions.CHANGE_NEED_END_TIME: {
            state = { ...state, endTime: action.payload }
            break;
        }
        case actions.CHANGE_NEED_END_DATE: {
            state = { ...state, endDate: action.payload }
            break;
        }
        case actions.CHANGE_NEED_SYNC_DATE: {
            state = { ...state, SyncDate: action.payload }
            break;
        }
        case actions.CHANGE_NEED_PLUS_DAY_DATE: {
            state = { ...state, PlusDayDate: action.payload }
            break;
        }
        case actions.CHANGE_NEED_IS_DURING: {
            state = { ...state, isDuringneed: action.payload }
            break;
        }
        case actions.CHANGE_NEED_PERIOD_OBJECT :{
            state = action.payload
            break;
        }
        case actions.CLEAR_NEED_DURING: {
            state = {}
            break;
        }
    }
    return state
}

export default PeriodNeedReducer
