import * as actions from '../../../../actions/ReActions/actionTypes'

const ReFinalNeedReducer = (state={}, action) => {
    switch(action.type){
        case actions.CHANGE_NEED_VISIBLE :{
            state = {...state, isVisibleNeed :action.payload }
            break;
        }
        
        case actions.CHANGE_NEED_DELETE_FINAL :{
            state = {}
            break;
        }
    }
    return state
  }
  
export default ReFinalNeedReducer
